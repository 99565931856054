<template>
  <div class="box-wrap">
    <!-- 设备列表 -->
    <div class="device-list-box">
      <div class="d-title">设备 Machine</div>
      <div class="list-box">
        <div class="device-head">
          <div class="text name1-no">序号 No</div>
          <div class="text name2">设备 Machine</div>
          <div class="text name1-status">状态 Status</div>
        </div>
        <div class="list-ctn-box">
          <div
            class="item"
            :class="deviceId == item.id ? 'item-active' : ''"
            v-for="(item, index) in deviceList"
            :key="index"
            @click="handleClick(item, index)"
          >
            <div class="text name1-no">{{ index + 1 }}</div>
            <div class="text name2">{{ item.name }}</div>
            <div class="text name1-status">
              <!-- status:1 正常   2关闭   3故障 -->
              <!-- 运行0、暂停1、空闲2、报警3 -->
              <div class="type1" v-if="item.machine_status == 0"></div>
              <div class="type2" v-if="item.machine_status == 2"></div>
              <img
                class="type3"
                v-if="item.machine_status == 3"
                src="@/assets/img/page/page2_pic1.png"
                alt
              />
              <div class="type4" v-if="item.machine_status == 1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 右侧内容 -->
    <div class="d-right-box">
      <div class="top-box">
        <date-search @onsearch="handleSearch"></date-search>
        <top-date :deviceNum="deviceNum"></top-date>
      </div>
      <div class="chart-box">
        <!-- 第一行 -->
        <div class="line-one">
          <div class="device-no">
            <div class="titles title-status-box">
              机器型号 Machine No : {{ deviceNo ? deviceNo : '' }}
              <!-- 运行0、暂停1、空闲2、报警3 -->
              <!-- <template v-if="deviceNoInfo.machine_status!=''"> -->
              <div class="status-box" v-if="deviceNoInfo.machine_status === 0">
                <div class="dot"></div>
                <div class="status-tex">运行中</div>
              </div>
              <div
                class="status-box status-box1"
                v-if="deviceNoInfo.machine_status == 1"
              >
                <div class="dot"></div>
                <div class="status-tex">暂停</div>
              </div>
              <div
                class="status-box status-box2"
                v-if="deviceNoInfo.machine_status == 2"
              >
                <div class="dot"></div>
                <div class="status-tex">空闲</div>
              </div>
              <div
                class="status-box status-box3"
                v-if="deviceNoInfo.machine_status == 3"
              >
                <div class="dot"></div>
                <div class="status-tex">报警</div>
              </div>
              <!-- </template> -->
            </div>
            <div class="pie-device-chart">
              <div class="pie-left" id="pieChart"></div>
              <div class="pie-right">
                <div class="list-box">
                  <div
                    class="rate-item"
                    v-for="(item, index) in deviceRateList"
                    :key="index"
                  >
                    <div
                      class="flg style1"
                      :class="['style' + (index + 1)]"
                    ></div>
                    <div class="name">{{ item.name }}</div>
                    <div
                      class="rate"
                      :class="item.value <= 50 ? 'r-color' : ''"
                    >
                      {{ handleCountComputed(item.value) }}%
                    </div>
                    <img
                      class="red"
                      v-if="item.value <= 50"
                      src="@/assets/img/page/page2_pic2.png"
                      alt
                    />
                  </div>
                </div>
                <div class="total-box">
                  <div class="total-item">
                    <div class="speed-text">
                      <div class="s-block"></div>
                      速度 Speed
                    </div>
                    <div class="num">{{ deviceNoInfo.cut_speed }}%</div>
                  </div>
                  <div class="total-item">
                    <div class="speed-text">
                      <div class="s-block"></div>
                      产量 Output
                    </div>
                    <div class="num">
                      {{ deviceNoInfo.reality_actual_count }}
                    </div>
                  </div>
                  <div class="total-item">
                    <div class="speed-text">
                      <div class="s-block"></div>
                      运行时间 Run time
                    </div>
                    <div class="num">{{ deviceNoInfo.cuttime_total }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="serve-icon">
            <div class="titles">运行情况 Machine statistics</div>
            <div class="run-box">
              <div
                class="run-item"
                v-for="(item, index) in runList"
                :key="index"
              >
                <div class="icon-box">
                  <img
                    :src="
                      require(
                        '@/assets/img/page/page2_icon' + (index + 1) + '.png',
                      )
                    "
                    class="icon"
                    alt
                  />
                </div>
                <div class="run-text">
                  <div class="name" :style="{ width: '80px' }">
                    {{ item.name }}
                  </div>
                  <div class="num" v-if="index == 4">{{ item.value }}</div>
                  <template v-else>
                    <dv-digital-flop
                      class="num"
                      :config="handleDigitalFlop(item.value, 24, '#7c828d')"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 第二行图表 -->
        <div class="line-two">
          <div class="chart-item">
            <div class="titles">综合效率 Overall efficiency</div>
            <div class="charts-wrap" id="efficiencyChart"></div>
          </div>
          <div class="chart-item">
            <div class="titles">停机时长 Downtime</div>
            <div class="charts-wrap" id="closeTimeChart"></div>
          </div>
        </div>
        <!-- 第三行-表格 -->
        <div class="table-box">
		  <a-tabs @change="onTabChange">
			<!-- 子表1-部件明细表 Component Detail -->
			<a-tab-pane key="component" tab="部件明细表 Component Detail">
			  <a-table
				:columns="componentColumns"
				:data-source="componentData"
				ref="componentDetailList"
				:row-key="(r, i) => JSON.stringify(r)"
				:pagination="componentTablePageConfig"
				@change="componentTablePageChange"
				:scroll="{ y: 490 }"
			  >
				<template slot="index" slot-scope="text, record, index">
				  {{
					(componentTablePageConfig.current - 1) * componentTablePageConfig.pageSize +
					index +
					1
				  }}
				</template>
			  </a-table>
			</a-tab-pane>
			<!-- 子表2-中断明细表 Downtime Detail -->
			<a-tab-pane key="downtime" tab="中断明细表 Downtime Detail">
            <a-table
              :columns="columns"
              :data-source="data"
              ref="tableList"
              :row-key="(r, i) => JSON.stringify(r)"
              :pagination="tablePageConfig"
              @change="tablePageChange"
              :scroll="{ y: 490 }"
            >
              <template slot="index" slot-scope="text, record, index">
                {{
                  (tablePageConfig.current - 1) * tablePageConfig.pageSize +
                  index +
                  1
                }}
              </template>
              <a slot="completionRate" slot-scope="text">
                <div class="text">{{ text }}%</div>
                <div class="line">
                  <div class="progress" :style="'width:' + text + '%'"></div>
                </div>
              </a>
            </a-table>
		  </a-tab-pane>
		 </a-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: '序号 No',
    scopedSlots: { customRender: 'index' },
    width: 100,
  },
  {
    title: '触发时间 Trigger time',
    dataIndex: 'BeginTime',
    key: 'BeginTime',
  },
  {
    title: '结束时间 End time',
    dataIndex: 'EndTime',
    key: 'EndTime',
  },
  {
    title: '持续时长 Duration (s)',
    dataIndex: 'Duration',
    key: 'Duration',
    // ellipsis: true,
  },
  {
    title: '原因 Cause',
    dataIndex: 'Cause',
    key: 'Cause',
    ellipsis: true,
  },
];

const componentColumns = [
  {
    title: '序号 No',
    scopedSlots: { customRender: 'index' },
    width: 100,
  },
  {
    title: '型体 Model',
    dataIndex: 'style_name',
    key: 'style_name',
  },
  {
    title: '部件 Component',
    dataIndex: 'module_name',
    key: 'module_name',
  },
  {
    title: '层数 Layer',
    dataIndex: 'layer',
    key: 'layer',
  },
  {
    title: '完成刀数 Cut',
    dataIndex: 'cut_count',
    key: 'cut_count',
    //ellipsis: true,
  },
  {
    title: '完成片段 Piece',
    dataIndex: 'cut_piece',
    key: 'cut_piece',
    //ellipsis: true,
  },
  {
    title: '切割时间 Cut Time',
    dataIndex: 'cut_time',
    key: 'cut_time',
    ellipsis: true,
  },
];

import { reqMachineList } from '@/api/index';
import { reqShutdownCause, reqStopData, reqApqOee, reqComponentDetail } from '@/api/devicePage.js';
import dateSearch from '@/components/dateSearch.vue';
import topDate from '@/components/topDate.vue';
import { formatSeconds } from '@/utils/index.js';
export default {
  components: {
    dateSearch,
    topDate,
  },
  data() {
    return {
      deviceList: [],
      deviceNoInfo: {
        cut_speed: 0,
        reality_actual_count: 0,
        cuttime_total: 0,
        machine_status: '',
      },
      //运行
      runList: [
        {
          value: 0,
          name: '裁切米数 Cut length',
        },
        {
          value: 0,
          name: '裁切次数 Number of cutting',
        },
        {
          value: 0,
          name: '冲孔次数 Number of punching',
        },
        {
          value: 0,
          name: '暂停次数 Number of pause',
        },
        {
          value: 0,
          name: '暂停时间 Down time',
        },
        {
          value: 0,
          name: '不良数 Defective quantity',
        },
      ],
      deviceRateList: [
        {
          name: '使用率 Availability',
          value: 0,
        },
        {
          name: '产能达成率 Performance',
          value: 0,
        },
        {
          name: '良品率 Quality',
          value: 0,
        },
        {
          name: '设备综合率 Overall equipment effectiveness',
          value: 0,
        },
      ],
      deviceId: '', //机器id
      deviceNo: '', //型号name
      deviceIndex: 0, //默认选中设备列表第一个
      deviceName: '', // 添加一个变量来存储设备名称
      twoXData: [], //综合效率x
      twoYDataOne: [], //综合y-oee
      twoYDataTwo: [], // A
      twoYDataThree: [], // P
      twoYDataFour: [], //Q
      dateType: 0, // 0:当天  1：当周  2：当月 3:自定义
      queryParam: {
        BeginTime: '',
        EndTime: '',
		Shift: 0,
      },
      deviceRate: {}, //机器型号
      stopTimesList: [],
      stopTimesXData: [],
      columns,//原有中断明细表
	  componentColumns,//新增部件明细表
      data: [],
	  componentData: [],
      timer: null,
      tablePageConfig: {
        // 总数据
        total: 0,
        // 每页条数
        pageSize: 10,
        // 当前页
        current: 1,
        // 显示多少页
        showTotal: (total) => `共有 ${total} 条 Total ${total} item`,
      },
	  componentTablePageConfig: {
	    // 总数据
	    total: 0,
	    // 每页条数
	    pageSize: 10,
	    // 当前页
	    current: 1,
	    // 显示多少页
	    showTotal: (total) => `共有 ${total} 条 Total ${total} item`,
	  },
      deviceNum: 0,
    };
  },
  mounted() {
    this.dateType = this.$store.state.searchType;
    this.queryParam.BeginTime = this.$store.state.startTimes;
    this.queryParam.EndTime = this.$store.state.endTimes;
	this.queryParam.Shift = this.$store.state.shift;
    this.getDeviceList();
    let _this = this;
    _this.timer = setInterval(function () {
      _this.twoXData = [];
      _this.twoYDataOne = [];
      _this.twoYDataTwo = [];
      _this.twoYDataThree = []; // P
      _this.twoYDataFour = []; //Q
      _this.stopTimesList = [];
      _this.stopTimesXData = [];
      _this.getDeviceList();
    }, 60000);
  },
  methods: {
    handleDigitalFlop(num, size, color) {
      let config1 = {
        number: [num],
        content: '{nt}',
        textAlign: 'left',
        style: {
          LineCap: 'square',
          fill: color,
          fontSize: size,
          fontWeight: 'bold',
        },
      };
      return config1;
    },
    otherNum(num) {
      if (num == 0) {
        return 100;
      } else {
        if (num) {
          return Number(100 - num).toFixed(2);
        } else {
          return 0;
        }
      }
    },
    handleSearch(val) {
      if (val) {
        this.dateType = val.dateType;
        this.queryParam.BeginTime = val.start_time;
        this.queryParam.EndTime = val.end_time;
		this.queryParam.Shift = val.shift;
      }
      this.deviceId = '';
      this.deviceNo = '';
      this.deviceIndex = 0;
      this.handleRefresh();
    },
    handleRefresh() {
      this.deviceList = [];

      this.twoXData = [];
      this.twoYDataOne = [];
      this.twoYDataTwo = [];
      this.twoYDataThree = []; // P
      this.twoYDataFour = []; //Q
      this.stopTimesList = [];
      this.stopTimesXData = [];
      this.deviceNoInfo.cut_speed = 0;
      this.deviceNoInfo.reality_actual_count = 0;
      this.deviceNoInfo.cuttime_total = 0;
      this.deviceNoInfo.machine_status = '';

      this.deviceRateList = [
        {
          name: '使用率 Availability',
          value: 0,
        },
        {
          name: '产能达成率 Performance',
          value: 0,
        },
        {
          name: '良品率 Quality',
          value: 0,
        },
        {
          name: '设备综合率 Overall equipment effectiveness',
          value: 0,
        },
      ];
      this.runList = [
        {
          value: 0,
          name: '裁切米数 Cut length',
        },
        {
          value: 0,
          name: '裁切次数 Number of cutting',
        },
        {
          value: 0,
          name: '冲孔次数 Number of punching',
        },
        {
          value: 0,
          name: '暂停次数 Number of pause',
        },
        {
          value: 0,
          name: '暂停时间 Down time',
        },
        {
          value: 0,
          name: '不良数 Defective quantity',
        },
      ];
      this.getDeviceList();
    },
    handleCountComputed(num) {
      if (num) {
        let count = Number(num).toFixed(2);
        return count;
      } else {
        return 0;
      }
    },
    getDeviceList() {
      let params = {
        DateType: this.dateType,
        MachineNO: '',
        BeginTime: this.queryParam.BeginTime,
        EndTime: this.queryParam.EndTime,
		Shift: this.queryParam.Shift,
      };
      reqMachineList(params)
        .then((res) => {
          // 2024-0308修改
          let arr = res.Data.MachineInfos; //原
          //let arr = res.Data.MachineTotals;

          // 去重并保留最新状态，一个machine_no在不同时间段可能有不同的显示状态
          this.deviceList = arr.reduce((acc, current) => {
            const existingDevice = acc.find(
              (item) => item.machine_no === current.machine_no,
            );
            if (!existingDevice) {
              return acc.concat([current]);
            } else {
              // machine_no用最新的状态替换现有的记录
              return acc.map((item) =>
                item.machine_no === current.machine_no ? current : item,
              );
            }
          }, []);
          this.deviceNum = this.deviceList.length;
          if (this.deviceList.length >= 1) {
            this.deviceNo = this.deviceList[this.deviceIndex].machine_no;
            this.deviceId = this.deviceList[this.deviceIndex].id;
            this.deviceName = this.deviceList[this.deviceIndex].name;
          }

          //this.deviceList = arr;原找寻machine_no的方法
          //this.deviceNum = arr.length;原
          //if (arr.length >= 1) {原
          //this.deviceNo = arr[this.deviceIndex].machine_no;原
          //this.deviceId = arr[this.deviceIndex].id;原
          //this.deviceName = arr[this.deviceIndex].name; // 存储设备名称//原
          //}
          this.getDeviceOnlyList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDeviceOnlyList() {
      let params = {
        DateType: this.dateType,
        MachineNO: this.deviceNo,
        BeginTime: this.queryParam.BeginTime,
        EndTime: this.queryParam.EndTime,
		Shift: this.queryParam.Shift,
      };
      reqMachineList(params)
        .then((res) => {
          //综合效率
          // 总
          let arr = res.Data.MachineTotals;

          if (arr.length >= 1) {
            //运行情况
            this.runList[0].value = arr[0].girth_total;
            this.runList[1].value = arr[0].cuttime_count;
            this.runList[2].value = arr[0].punching_count;
            this.runList[3].value = arr[0].pause_count;
            this.runList[4].value = formatSeconds(arr[0].pause_total);
            this.runList[5].value = arr[0].produce_uqty;
            //机器型号
            this.deviceRateList[0].value = arr[0].machine_usage_rate * 100; //使用率
            this.deviceRateList[1].value = arr[0].machine_finish_rate * 100; //产能达成率
            this.deviceRateList[2].value = arr[0].machine_product_rate * 100; //良品率
            this.deviceRateList[3].value = arr[0].oee * 100; //设备综合率

            //机器型号-速度、产量、运行时间、状态
            this.deviceNoInfo.cut_speed = arr[0].cut_speed;
            this.deviceNoInfo.reality_actual_count =
              arr[0].reality_actual_count;
            this.deviceNoInfo.cuttime_total = formatSeconds(
              arr[0].cuttime_total,
            );
            this.deviceNoInfo.machine_status = arr[0].machine_status;
          }

          //日综合效率
          this.getOeeData();
          this.getChartsOne();
          //停机时长
          this.getStopList();
		  

          this.tablePageConfig.total = 0;
          this.tablePageConfig.current = 1;
          this.getTableList();
		  this.componentTablePageConfig.total = 0;
		  this.componentTablePageConfig.current = 1;
		  this.getComponentDetailList();//新增部件明细
		  
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOeeData() {
      if (this.deviceNo) {
        let params = {
          MachineNO: this.deviceNo,
          DateType: this.dateType,
          BeginTime: this.queryParam.BeginTime,
          EndTime: this.queryParam.EndTime,
		  Shift: this.queryParam.Shift,
        };
        reqApqOee(params).then((res) => {
          if (res.Code == 0) {
            if (res.Data.ApqOees && res.Data.ApqOees.length > 0) {
              let arr = res.Data.ApqOees;
              arr.forEach((item) => {
                this.twoXData.push(item.begin_time);
                this.twoYDataOne.push(this.handleCountComputed(item.oee * 100));
                this.twoYDataTwo.push(
                  this.handleCountComputed(item.machine_usage_rate * 100),
                );
                this.twoYDataThree.push(
                  this.handleCountComputed(item.machine_finish_rate * 100),
                );
                this.twoYDataFour.push(
                  this.handleCountComputed(item.machine_product_rate * 100),
                );
              });
              this.getChartsTwo();
            }
          }
        });
      }
    },
    getStopList() {
      // 获取停机时长数据
      let params = {
        DateType: this.dateType,
        MachineNO: this.deviceNo,
        BeginTime: this.queryParam.BeginTime,
        EndTime: this.queryParam.EndTime,
		Shift: this.queryParam.Shift,
      };
      reqStopData(params)
        .then((res) => {
          if (res.Code == 0) {
            this.stopTimesList = [];
            this.stopTimesXData = [];
            let colorList = [
              '#2759FF',
              '#3A91FD',
              '#1EDB7B',
              '#FABF05',
              '#F7DF00',
              '#39B3FF',
              '#47E0E0',
              '#7891D9',
              '#83D978',
              '#C7A530',
              '#FF8439',
            ];
            let count = 0;
            if (
              res.Data &&
              res.Data.ShutDownTotals &&
              res.Data.ShutDownTotals.length >= 1
            ) {
              let arr = res.Data.ShutDownTotals;

              arr.forEach((item) => {
                if (count == 11) {
                  count = 0;
                }
                this.stopTimesList.unshift({
                  value: item.duration,
                  name: item.cause,
                  color: colorList[count],
                });
                this.stopTimesXData.unshift({
                  value: item.cause,
                  color: colorList[count],
                });
                count++;
              });
              console.log('======>停机数据', this.stopTimesList);
              this.getChartsThree();
            } else {
              this.getChartsThree();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatSeconds(seconds) {
      if (!seconds) return 0;
      else {
        let secondTime = parseInt(seconds); //将传入的秒的值转化为Number
        let min = 0; // 初始化分
        let h = 0; // 初始化小时
        let result = '';
        if (secondTime >= 60) {
          //如果秒数大于等于60，将秒数转换成整数
          min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
          secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
          if (min >= 60) {
            //如果分钟大于等于60，将分钟转换成小时
            h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
            min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
          }
        }
        if (h) {
          result = `${h.toString().padStart(2, '00')}:${min
            .toString()
            .padStart(2, '00')}:${secondTime.toString().padStart(2, '00')}`;
        } else if (min) {
          result = `00:${min.toString().padStart(2, '00')}:${secondTime
            .toString()
            .padStart(2, '00')}`;
        } else {
          result = `00:00:${secondTime.toString().padStart(2, '00')}`;
        }
        return result;
      }
    },
    handleClick(item, index) {
      if (item.id == this.deviceId) return false;

      this.deviceNoInfo = item;

      this.deviceNo = item.machine_no;
      this.deviceId = item.id;
      this.deviceIndex = index;
      this.deviceName = item.name; // 在点击时更新设备名称
      this.twoXData = [];
      this.twoYDataOne = [];
      this.twoYDataTwo = [];
      this.twoYDataThree = [];
      this.twoYDataFour = [];

      this.stopTimesList = [];
      this.stopTimesXData = [];
      this.getChartsTwo();
      this.getDeviceOnlyList();
    },
    getTableList() {
      let params = {
        DateType: this.dateType,
        MachineNO: this.deviceNo,
        BeginTime: this.queryParam.BeginTime,
        EndTime: this.queryParam.EndTime,
		Shift: this.queryParam.Shift,
        PageNo: this.tablePageConfig.current,
        PageSize: this.tablePageConfig.pageSize,
      };
      reqShutdownCause(params).then((res) => {
        if (res.Code == 0) {
          this.data = res.Data.ShutdownCauses;
          this.tablePageConfig.total = res.Data.ShutdownCause_Size;
        }
      });
    },
	// 新增部件明细表
	getComponentDetailList() {
	  let params = {
	    DateType: this.dateType,
	    MachineNo: this.deviceNo,
	    BeginTime: this.queryParam.BeginTime,
		EndTime: this.queryParam.EndTime,
		Shift: this.queryParam.Shift,
	    PageNo: this.componentTablePageConfig.current,
	    PageSize: this.componentTablePageConfig.pageSize,
	  };
	  reqComponentDetail(params).then((res) => {
	    if (res.Code == 0) {
	      this.componentData = res.Data.ModuleDetail;
	      this.componentTablePageConfig.total = res.Data.ModuleDetail_Size;
	    }
	  });
	},
    tablePageChange(pagination) {
      console.log('====>');
      // 分页
      this.tablePageConfig.current = pagination.current;
      this.getTableList();//原中断明细表
    },
	componentTablePageChange(pagination) {
	  console.log('====>');
	  // 分页
	  this.componentTablePageConfig.current = pagination.current;
	  this.getComponentDetailList();//新增部件明细表
	},
    getChartsOne() {
      // 饼
      const chartBox = this.$echarts.init(document.getElementById('pieChart'));
      let dataStyle = {
        normal: {
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          shadowBlur: 40,
          borderWidth: 10,
          shadowColor: 'rgba(0, 0, 0, 0)', //边框阴影
        },
        borderRadius: 80,
        borderColor: '#f9f9f9',
      };
      let placeHolderStyle = {
        normal: {
          color: '#f9f9f9',
          borderWidth: 50,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
        },
        emphasis: {
          color: '#f9f9f9',
        },
      };
      let option = {
        backgroundColor: '#fff',
        title: {
          // text: '匹配度',
          x: 'center',
          y: 'center',
          textStyle: {
            fontWeight: 'normal',
            fontSize: 24,
            color: '#fff',
          },
        },
        tooltip: {
          capType: 'round',
          trigger: 'item',
          show: true,
          formatter: '{b} : <br/>{d}%',
          backgroundColor: '#ddd', // 背景
          padding: [8, 10], //内边距
          extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);', //添加阴影
        },
        series: [
          {
            name: 'A',
            type: 'pie',
            clockWise: false,
            radius: [85, 100],
            center: ['50%', '50%'],
            itemStyle: dataStyle,
            hoverAnimation: false,
            startAngle: 90,
            label: {
              borderRadius: '10',
            },
            data: [
              {
                value: this.deviceRateList[0].value,
                name: 'A',
                itemStyle: {
                  normal: {
                    color: '#FF4A4A',
                  },
                },
              },
              {
                value: this.otherNum(this.deviceRateList[0].value),
                name: '',
                tooltip: {
                  show: false,
                },
                itemStyle: placeHolderStyle,
              },
            ],
          },
          {
            name: 'P',
            type: 'pie',
            clockWise: false,
            radius: [65, 75],
            center: ['50%', '50%'],
            itemStyle: dataStyle,
            hoverAnimation: false,
            startAngle: 90,
            data: [
              {
                value: this.deviceRateList[1].value,
                name: 'P',
                itemStyle: {
                  normal: {
                    color: '#FFAF24',
                  },
                },
              },
              {
                value: this.otherNum(this.deviceRateList[1].value),
                name: '',
                tooltip: {
                  show: false,
                },
                itemStyle: placeHolderStyle,
              },
            ],
          },
          {
            name: 'Q',
            type: 'pie',
            clockWise: false,
            radius: [45, 55],
            center: ['50%', '50%'],
            itemStyle: dataStyle,
            hoverAnimation: false,
            startAngle: 90,
            data: [
              {
                value: this.deviceRateList[2].value,
                name: 'Q',
                itemStyle: {
                  normal: {
                    color: '#4A8EF3',
                  },
                },
              },
              {
                value: this.otherNum(this.deviceRateList[2].value),
                name: '',
                tooltip: {
                  show: false,
                },
                itemStyle: placeHolderStyle,
              },
            ],
          },
          {
            name: 'OEE',
            type: 'pie',
            clockWise: false,
            radius: [25, 35],
            center: ['50%', '50%'],
            itemStyle: dataStyle,
            hoverAnimation: false,
            startAngle: 90,
            data: [
              {
                value: this.deviceRateList[3].value,
                name: 'OEE',
                itemStyle: {
                  normal: {
                    color: '#1EDB7B',
                  },
                },
              },
              {
                value: this.otherNum(this.deviceRateList[3].value),
                name: '',
                tooltip: {
                  show: false,
                },
                itemStyle: placeHolderStyle,
              },
            ],
          },
        ],
      };
      // chartBox.clear();
      chartBox.setOption(option);
    },
    getChartsTwo() {
      const chartBox = this.$echarts.init(
        document.getElementById('efficiencyChart'),
      );
      let scrollPosition;
      if (this.twoXData.length > 8) {
        scrollPosition = 50;
      } else {
        scrollPosition = 96;
      }
      let option = {
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            console.log(params);
            let result = '';
            params.forEach((item) => {
              const oname = item.seriesName;
              const val = item.data;
              result += `<div style="width:10px;height:10px;border-radius:50%;display:inline-block;margin-right:5px;background:${item.color}"></div>${oname}：${val}%<br/>`;
            });

            return result;
          },
        },
        legend: {
          //   orient: "vertical",
          icon: 'rect',
          right: 0,
          top: 0,
          itemGap: 20,
          data: ['A', 'P', 'Q', 'OEE'],
          textStyle: {
            color: '#878C93',
          },
          itemWidth: 12,
          itemHeight: 10,
        },
        grid: {
          top: '10%',
          left: '0%',
          right: '2%',
          bottom: '15%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            rotate: 40,
          },
          data: this.twoXData,
        },
        yAxis: {
          type: 'value',
          name: '单位 Unit(%)',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#eeeeee',
            },
          },
          axisLabel: {
            show: true,
            interval: 0, // 使x轴文字显示全
            color: '#666666',
            formatter: '{value}%', //y轴数值，带百分号
          },
          axisLine: { show: false, lineStyle: { color: '#000' } },
        },
        dataZoom: [
          {
            type: 'slider',
            show: true,
            height: 20,
            bottom: '5%',
            startValue: 0,
            endValue: 17, // 一次性展示15个
            realtime: true, //是否实时更新
            brushSelect: false, //刷选功能

            handleStyle: {
              color: '#d3dee5',
            },
            textStyle: {
              color: '#000',
            },
            backgroundColor: '#f1f1f1',
            selectedDataBackground: '#FCEBDC',
            dataBackground: {
              areaStyle: {
                color: '#2579FF',
              },
            },
            fillerColor: '#FCEBDC',
          },
        ],
        series: [
          {
            data: this.twoYDataOne,
            type: 'line',
            name: 'OEE',
            smooth: true,
            symbolSize: 8,
            symbol: 'circle',
            lineStyle: {
              width: 2,
            },
            itemStyle: {
              color: '#1EDB7B', // 设置线条颜色
            },
            areaStyle: {
              normal: {
                color: 'rgba(30, 219, 123, 0.05)',
              },
            },
          },
          {
            data: this.twoYDataTwo,
            type: 'line',
            name: 'A',
            smooth: true,
            symbolSize: 8,
            symbol: 'circle',
            lineStyle: {
              width: 2,
            },
            itemStyle: {
              color: '#FF4A4A', // 设置线条颜色
            },
            areaStyle: {
              normal: {
                color: 'rgba(255, 74, 74, 0.05)',
              },
            },
          },
          {
            data: this.twoYDataThree,
            type: 'line',
            name: 'P',
            smooth: true,
            symbolSize: 8,
            symbol: 'circle',
            lineStyle: {
              width: 2,
            },
            itemStyle: {
              color: '#FFAF24', // 设置线条颜色
            },
            areaStyle: {
              normal: {
                color: 'rgba(255, 175, 36, 0.05)',
              },
            },
          },
          {
            data: this.twoYDataFour,
            type: 'line',
            name: 'Q',
            smooth: true,
            symbolSize: 8,
            symbol: 'circle',
            lineStyle: {
              width: 2,
            },
            itemStyle: {
              color: '#4A8EF3', // 设置线条颜色
            },
            areaStyle: {
              normal: {
                color: 'rgba(74, 142, 234, 0.05)',
              },
            },
          },
        ],
      };
      chartBox.clear();
      chartBox.setOption(option);
    },
    getChartsThree() {
      let _this = this;
      const chartBox = _this.$echarts.init(
        document.getElementById('closeTimeChart'),
      );

      let option = {
        tooltip: {
          trigger: 'item',
          //鼠标移入
          // formatter: "{a} <br/>{b} : {c}h"
          formatter: function (params) {
            return params.name + ':' + _this.formatSeconds(params.value);
          },
        },
        grid: {
          top: '1%',
          left: '0%',
          right: '0%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          axisLine: {
            show: true,
          },
          splitLine: {
            show: false,
          },
        },

        yAxis: [
          {
            type: 'category',
            // inverse: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: '#333',
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: true,
            },
            axisLine: {
              show: true,
            },
            data: _this.stopTimesXData,
          },
          {
            type: 'category',
            // inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#333',
                fontSize: '12',
              },
              formatter: function (value) {
                return _this.formatSeconds(value);
              },
            },
            data: _this.stopTimesList,
          },
        ],
        series: [
          {
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: (params) => {
                  console.log(params, '===ddddd');
                  return params.data.color;
                },
              },
            },
            barWidth: 20,
            data: this.stopTimesList,
          },
        ],
      };
      // chartBox.clear();
      chartBox.setOption(option);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.box-wrap {
  width: 100%;
  height: 100%;
  background: #f4f5f7; //f4f5f7
  overflow: hidden;
  display: flex;
  .device-list-box {
    width: 310px;
    height: 1080px;
    padding: 25px 0;
    background: #fff; //fff
    overflow: hidden;
    .d-title {
      height: 26px;
      line-height: 26px;
      font-size: 18px;
      font-weight: bold;
      //background: #ec3c8b;
      color: #3f4653;
      position: relative;
      padding-left: 12px;
      margin-left: 18px;
      &::before {
        position: absolute;
        content: '';
        width: 6px;
        height: 18px;
        border-radius: 40px;
        background: #f5812d;
        left: 0;
        top: 3px;
      }
    }
    .list-box {
      width: 400px;
      height: 100%;
      //background: #2c94da;
      overflow: hidden;
      margin: 25px auto 0;
      .name1-no {
        width: 59px; /* 设置序号 No 列的宽度 */
      }
      .name1-status {
        width: 88px; /* 设置状态 Status 列的宽度 */
      }
      .name2 {
        flex: 1;
        //防止超出范围自动换行，以省略号表示
        white-space: nowrap; /* 防止文本换行 */
        overflow: hidden; /* 隐藏超出容器部分 */
        text-overflow: ellipsis; /* 超出部分以省略号显示 */
        text-align: center; /* 保持 list-box 中 name2 的居中 */
      }
      .device-head {
        width: 310px;
        height: 46px;
        line-height: 46px;
        border-radius: 10px;
        background: #f5f6f7;
        // padding: 0 18px;
        display: flex;
        justify-content: space-between;
        .text {
          font-size: 16px;
          color: #515d72;
          font-weight: bold;
          text-align: center;
        }
      }
      .list-ctn-box {
        width: 310px;
        height: 92%;
        //background: #2d2e2e;//自己加的
        overflow-y: scroll;
        .item {
          text-align: center;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          //   padding: 14px 0;
          height: 50px;
          line-height: 50px;
          /* 修改只针对 list-ctn-box 中的 name2 */
          .name2 {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left; /* 左对齐，只对 list-ctn-box 生效 */
          }

          .text {
            font-size: 14px;
            color: #808792;

            .type1 {
              width: 10px;
              height: 10px;
              background: #1edb7b;
              border-radius: 50%;
              margin: 0 auto;
            }
            .type2 {
              width: 10px;
              height: 10px;
              background: #dbdbdb;
              border-radius: 50%;
              margin: 0 auto;
            }
            .type3 {
              display: block;
              width: 28px;
              height: 28px;
              margin: 0 auto;
            }
            .type4 {
              width: 10px;
              height: 10px;
              background: #f5812d;
              border-radius: 50%;
              margin: 0 auto;
            }
          }
        }
        .item-active {
          //width: 264px;
          width: 310px;
          height: 50px;
          background: linear-gradient(
            90deg,
            #f8ddc8 0%,
            rgba(255, 255, 255, 0) 170%
          );
          position: relative;
          ::before {
            content: '';
            position: absolute;
            left: 0;
            top: 10px;
            width: 6px;
            height: 30px;
            border-radius: 83px;
            background: #f5812d;
          }
        }
      }
      .list-ctn-box::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .d-right-box {
    // width: 100%;
    flex: 1;
    overflow: hidden;
    .top-box {
      width: 98%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      background: #ffffff;
    }
    .chart-box {
      height: 1005px;
      padding: 11px 22px 22px 22px;
      // border: 1px solid #f00;
      overflow-y: scroll;
      .titles {
        height: 26px;
        line-height: 26px;
        font-size: 18px;
        font-weight: bold;
        color: #3f4653;
        position: relative;
        padding-left: 12px;
        &::before {
          position: absolute;
          content: '';
          width: 6px;
          height: 18px;
          border-radius: 40px;
          background: #f5812d;
          left: 0;
          top: 3px;
        }
      }
      .title-status-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .status-box {
          width: 86px;
          height: 30px;
          font-size: 16px;
          color: #56c456;
          border-radius: 100px;
          background: rgba(225, 255, 225, 0.85);
          backdrop-filter: blur(1px);
          // padding: 0 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #56c456;
            margin-right: 6px;
          }
        }
        .status-box2 {
          background: #dbdbdb;
          color: #999;
          .dot {
            background: #999;
          }
        }
        .status-box1 {
          background: rgba(245, 129, 45, 0.2);
          color: #f5812d;
          .dot {
            background: #f5812d;
          }
        }
        .status-box3 {
          background: #ffe0e0;
          color: #ff6261;
          .dot {
            background: #ff6261;
          }
        }
      }
      .line-one {
        display: flex;
        justify-content: space-between;
        padding: 11px 0;
        .device-no {
          width: 798px;
          height: 340px;
          border-radius: 10px;
          padding: 25px 18px 0;
          background-color: #fff;
          background-image: url('../assets/img/page/page2_bg1.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: 410px -180px;
        }
        // 机器型号
        .pie-device-chart {
          padding: 30px 40px 0;
          display: flex;
          justify-content: space-between;
          // border: 1px solid #f00;
          .pie-left {
            flex-shrink: 0;
            width: 210px;
            min-height: 210px;
            margin-right: 20px;
            // border: 1px solid #f00;
          }
          .pie-right {
            flex: 1;
            .list-box {
              display: flex;
              flex-wrap: wrap;
              padding-left: 38px;
              .rate-item {
                display: flex;
                align-items: center;
                min-width: 200px;
                margin-top: 10px;
                .flg {
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  margin-right: 8px;
                }
                .name {
                  font-size: 16px;
                  color: #878c93;
                }

                .style1 {
                  background: #ff4a4a;
                }
                .style2 {
                  background: #ffaf24;
                }
                .style3 {
                  background: #4a8ef3;
                }
                .style4 {
                  background: #1edb7b;
                }
                .rate {
                  // width:140px;
                  font-size: 16px;
                  color: #626873;
                  font-weight: bold;
                  padding-left: 26px;
                }
                .red {
                  width: 16px;
                  height: 16px;
                  margin-left: 8px;
                  display: block;
                }
                .r-color {
                  color: #ff2929;
                }
              }
            }
            .total-box {
              width: 490px;
              height: 110px;
              border-radius: 10px;
              background: #f9f9f9; //f9f9f9
              margin: 33px auto 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              text-align: center;
              .total-item {
                flex: 1;
                &:nth-child(2) {
                  position: relative;

                  &::before {
                    content: '';
                    width: 2px;
                    height: 46px;
                    background: #dfdfdf;
                    position: absolute;
                    left: 0;
                    top: 20%;
                  }
                  &::after {
                    content: '';
                    width: 2px;
                    height: 46px;
                    background: #dfdfdf;
                    position: absolute;
                    right: 0;
                    top: 20%;
                  }
                }
                .speed-text {
                  height: 24px;
                  line-height: 24px;
                  font-size: 16px;
                  color: rgba(135, 140, 147, 0.8);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .s-block {
                    width: 10px;
                    height: 10px;
                    background: rgba(135, 140, 147, 0.2);
                    margin-right: 6px;
                  }
                }
                .num {
                  line-height: 44px;
                  font-size: 30px;
                  font-weight: bold;
                  color: rgba(63, 70, 83, 0.8);
                }
              }
            }
          }
        }
        // 运行情况
        .serve-icon {
          width: 707px;
          height: 339px;
          border-radius: 10px;
          padding: 25px 18px 0;
          //background: #841ee3;
          background: #ffffff;
          .run-box {
            height: 286px;
            //background: #dd2424;//自己
            padding: 45px 1px 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .run-item {
              // flex: 1
              width: 33.3%;
              //background: #ffffff;
              padding: 8px 40px 20px 10px;
              //display: flex;
              //align-content: center;
              //width: calc(33.33% - 10px); /* 每个项目宽度为三分之一，考虑间隙 */
              margin-bottom: 40px; /* 底部间隔 */
              display: flex;
              .icon-box {
                width: 65px;
                height: 54px;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                margin-top: -20px;
                //background: #e1d24b;
                //background: linear-gradient(180deg, #fff6ef 0%, #ffe2cc 100%);
                .icon {
                  display: block;
                  width: 33px;
                }
              }
              .run-text {
                //line-height: 1;
                //flex-grow: 1; /* 右侧文字部分占据剩余空间 */
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .name {
                  font-size: 15px;
                  color: #a8adb3;
                  height: 68px;
                  line-height: 18px;
                  //background-color: #1edb7b;
                  margin-top: -40px;
                }
                .num {
                  font-size: 25px;
                  //background-color: #4a8ef3;
                  height: 36px;
                  line-height: 36px;
                  font-weight: bold;
                  color: #7c828d;
                }
              }
            }
          }
        }
      }
      .line-two {
        display: flex;
        justify-content: space-between;

        .chart-item {
          width: 752px;//652px;
          height: 365px;
          border-radius: 10px;
          box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
          background: #ffffff;
          padding: 25px 18px 0;
          margin: 11px 0;

          .charts-wrap {
            width: 100%;
            height: 304px;
            // border: 1px solid #f00;
          }
        }
      }
      .table-box {
        width: 1326px;
        min-height: 500px;
        padding: 25px 18px 0;
        background: #ffffff;
      }
    }
  }
}
</style>

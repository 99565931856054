import request from '@/utils/request';

const userApi = {
  tableList: '/api/shutdown_cause',
  stopList: '/api/shutdown_total',
  dayApqOee: '/api/day_apq_oee', //丢弃
  apqOee: '/api/apq_oee', //综合效率
  componentDetailList: '/api/module_detail',//新增部件明细

  // tableListShift: '/api/cause_shift', //新增班次查询方式
  // stopListShift: '/api/shutdown_shift', //新增班次查询方式
  // //dayApqOeeShift:'/api/day_apq_oee',//丢弃
  // apqOeeShift: '/api/apqoee_shift', //综合效率  //新增班次查询方式
};

// 表格数据
export function reqShutdownCause(parameter) {
  return request({
    url: userApi.tableList,
    method: 'post',
    data: parameter,
  });
}
//获取停机时长
export function reqStopData(parameter) {
  return request({
    url: userApi.stopList,
    method: 'post',
    data: parameter,
  });
}

// 天-设备综合效率---2024-03-13弃用
export function reqDayApqOee(parameter) {
  return request({
    url: userApi.dayApqOee,
    method: 'post',
    data: parameter,
  });
}
export function reqApqOee(parameter) {
  return request({
    url: userApi.apqOee,
    method: 'post',
    data: parameter,
  });
}
//获取部件明细:表格数据
export function reqComponentDetail(parameter) {
  return request({
    url: userApi.componentDetailList,
    method: 'post',
    data: parameter,
  });
}

// // 表格数据 ：指定班次
// export function reqShutdownCauseShift(parameter) {
//   return request({
//     url: userApi.tableListShift,
//     method: 'post',
//     data: parameter,
//   });
// }
// // 获取停机时长 ：指定班次
// export function reqStopDataShift(parameter) {
//   return request({
//     url: userApi.stopListShift,
//     method: 'post',
//     data: parameter,
//   });
// }
// // 综合效率：指定班次
// export function reqApqOeeShift(parameter) {
//   return request({
//     url: userApi.apqOeeShift,
//     method: 'post',
//     data: parameter,
//   });
// }
